<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()"
          ><i class="el-icon-error"></i
        ></span>
        <div class="wb-tit font18">发送Offer</div>
        <div class="updatabox">
          <el-form
            :model="infoValidateForm"
            ref="infoValidateForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="职级名称："
              prop="jobLevelId"
              :rules="[
                { required: true, message: '请选择职级名称', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="infoValidateForm.jobLevelId"
                placeholder="请选择"
                style="width: 450px"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="到岗时间："
              prop="joinDate"
              :rules="[
                { required: true, message: '请选择到岗时间', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                v-model="infoValidateForm.joinDate"
                type="datetime"
                placeholder="请选择到岗日期"
                style="width: 450px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="公司地址："
              prop="companyBuild"
              :rules="[
                { required: true, message: '请选择公司地点', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="companyAdress"
                placeholder="请选择公司地点"
                style="width: 450px"
                @click.native="chooseaddress()"
              >
              </el-input>
            </el-form-item>
            <!-- submitForm('infoValidateForm') -->
            <el-form-item>
              <el-button
                type="primary"
                class="btn"
                @click="submitForm('infoValidateForm')"
              >
                确认发送
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      dialogVisible: false,
      infoValidateForm: {
        jobLevelId: "",
        address: "",
        companyBuild: "",
        joinDate: "",
        companyJobId: "",
        offerSalary: "",
        userOfferId: "",
        lat: 0,
        lng: 0,
        userJobId: "",
      },
      companyAdress: "",
      joinTime: "",
      options: [],
      config: {},
    };
  },
  activated() {
    this.getJobLevel();
  },
  mounted() {
    Bus.$on("offerstuts", (data) => {
      // console.log("退场接受到", data);
      this.dialogVisible = data.show;
      this.config = data.data;
    });
  },
  methods: {
    getJobLevel() {
      this.$api.getJoblevel("get").then((res) => {
        let arr = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.rankName,
          };
          arr.push(obj);
        });
        this.options = arr;
      });
    },
    closebox() {
      this.dialogVisible = false;
      // Bus.$emit('reportdstuts', false)
    },
    chooseaddress() {
      this.dialogVisible = false;
      Bus.$emit("workingplacestuts", {
        show: true,
        callback: (config) => {
          this.infoValidateForm.address = config.address;
          this.infoValidateForm.companyBuild = config.companyBuild;
          this.infoValidateForm.lng = config.lng;
          this.infoValidateForm.lat = config.lat;
          this.companyAdress = config.address + config.companyBuild;
          this.dialogVisible = true;

          // console.log(this.infoValidateForm);
        },
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.infoValidateForm.userOfferId = this.config.id
          this.infoValidateForm.companyJobId = this.config.companyJobId;
          this.infoValidateForm.userJobId = this.config.userJobId;
          // this.infoValidateForm.offerSalary = 10000
          let config = JSON.parse(JSON.stringify(this.infoValidateForm));
          let startTime = new Date(config.joinDate);
          let y = startTime.getFullYear();
          let m = startTime.getMonth() + 1;
          let d = startTime.getDate();
          let h = startTime.getHours();
          let f = startTime.getMinutes();
          let s = startTime.getSeconds();
          config.joinDate = y + "-" + m + "-" + d + " " + h + ":" + f + ":" + s;
          this.$api
            .reSendInterview("post", {
              interviewId: this.config.id,
              status: 4,
            })
            .then(() => {});
          this.$api.sendOffer("post", config).then(() => {
            this.dialogVisible = false;
            // this.fasong();
            this.$message({
              message: "发送成功",
              type: "success",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        } else {
          return false;
        }
      });
    },
    fasong() {
      this.closebox();
      this.$emit("changestuts", true);
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  padding-bottom: 20px;
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
}

.updatabox {
  .demo-ruleForm {
    padding: 50px 80px 0px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
      // width: 450px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 450px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .apply_tishi {
      display: flex;
      align-items: flex-start;
      width: 450px;
      .p-img {
        padding: 2px 8px 0 8px;
        color: #ff4444;
        display: flex;
        align-items: flex-start;
      }
      .p-cont {
        display: flex;
        flex: 1;
        color: #666666;
        line-height: 1.5;
        text-align: left;
      }
    }
  }
}
</style>
