<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="700px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="details">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">工作地点</div>
        <div class="frombox">
          <el-form
            :model="updataForm"
            ref="updataForm"
            size="small"
            label-width="130px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item
              label="街道/写字楼："
              prop="address"
              :rules="[
                { required: true, message: '请输入行政区、街道、写字楼' },
              ]"
            >
              <el-input
                v-model="updataForm.address"
                placeholder="请输入行政区、街道、写字楼"
                style="width: 430px"
              ></el-input>
            </el-form-item> -->

            <el-form-item
              label="街道/写字楼："
              prop="address"
              :rules="[
                { required: true, message: '请输入行政区、街道、写字楼' },
              ]"
            >
              <el-select
                v-model="tipInput"
                class="tipInput"
                filterable
                remote
                reserve-keyword
                clearable
                placeholder="请输入行政区、街道、写字楼"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="tipChange"
                style="width: 100%"
              >
                <el-option
                  v-for="item in tips"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.district
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="门牌号："
              prop="companyBuild"
              :rules="[{ required: true, message: '请填写真实地址' }]"
            >
              <el-input
                v-model="updataForm.companyBuild"
                placeholder="请填写真实地址"
               style="width: 100%"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <img
                v-if="!markers.length"
                class="amap-wrapper"
               style="width: 100%"
                src="../../assets/images/common/map.png"
              />
              <div class="amap-wrapper" v-else style="width: 100%">
                <el-amap
                  class="amap-box"
                  :zoom="zoom"
                  :center="center"
                  style="width: 430px"
                  :mapStyle="mapStyle"
                >
                  <el-amap-marker
                    v-for="(marker, index) in markers"
                    :position="marker.position"
                    :events="marker.events"
                    :vid="index"
                    :icon="marker.icon"
                    v-bind:key="index"
                    style="width: 430px"
                  >
                  </el-amap-marker>
                  <el-amap-info-window
                    v-if="window"
                    :position="window.position"
                    :visible="window.visible"
                    :content="window.content"
                    :offset="window.offset"
                    :is-custom="true"
                    style="width: 430px"
                  >
                    <div id="info-window">
                      <p>{{ window.address }}</p>
                    </div>
                  </el-amap-info-window>
                </el-amap>
              </div>
            </el-form-item>

            <el-form-item>
              <el-button class="qux" @click="dialogVisible = false"
                >取消</el-button
              >
              <el-button
                type="primary"
                class="btn-next"
                @click="submitForm('updataForm')"
                >保存地址</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import location from "../../assets/images/size.png";
import Bus from "../../common/bus.js";

export default {
  data() {
    return {
      zoom: 14,
      center: [],
      mapStyle: "amap://styles/8b6be8ec497009e17a708205348b899a", //设置地图样式
      markers: [],
      windows: [],
      tipInput: "",
      loading: false,
      map: undefined,
      tips: [],
      window: "",
      dialogVisible: false,
      updataForm: {
        address: "",
        companyBuild: "",
      },
      updialogVisible: false,
      callback: null,
    };
  },
  watch: {
    dialogVisible(value) {
      if (!value) {
        this.dialogVisible = false;
        this.callback(this.updataForm);
      }
    },
  },
  mounted() {
    Bus.$on("workingplacestuts", (data) => {
      this.dialogVisible = data.show;
      this.callback = data.callback;
    });
  },
  methods: {
    tipChange(value) {
      for (const tip of this.tips) {
        if (value === tip.id) {
          this.updataForm.address = tip.name;
          let markers = [];
          let windows = [];
          let basePosition = [tip.location.lng, tip.location.lat];
          // let icon = new AMap.Icon({
          // 	image: location,
          // 	size: new AMap.Size(2, 2),
          // 	imageSize: new AMap.Size(2, 2)
          // });
          // content添加相应的内容**********
          markers.push({
            position: basePosition,
            icon: new AMap.Icon({
              image: location,
              size: new AMap.Size(40, 40),
              imageSize: new AMap.Size(40, 40),
            }),
            text: "content1111",
            offset: [0, -50],
          });
          windows.push({
            position: basePosition,
            isCustom: true,
            offset: [10, -40], // 窗体偏移
            visible: true, // 初始是否显示
            address: tip.name,
          });
          this.zoom = 15;
          this.markers = markers;
          this.windows = windows;
          this.center = [tip.location.lng, tip.location.lat];
          this.window = windows[0];
          break;
        }
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const that = this;
        AMap.plugin("AMap.Autocomplete", function () {
          // 实例化Autocomplete
          const autoOptions = {
            city: "全国",
          };
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function (status, result) {
            // console.log(">>>>>>", result);
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || [];
            that.loading = false;
          });
        });
      } else {
        this.tips = [];
      }
    },
    closebox() {
      this.dialogVisible = false;
      this.callback(this.updataForm);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          if (this.center.length > 0) {
            this.updataForm["lng"] = this.center[0];
            this.updataForm["lat"] = this.center[1];
          }
          this.callback(this.updataForm);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style type="text/css">
.tipInput {
  width: 300px;
}
.amap-wrapper {
  height: 250px;
  margin-bottom: 20px;
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.details {
  .wb-tit {
    padding: 17px 0;
    text-align: center;
    background-color: #f3f3f3;
    color: #000000;
    font-weight: 600;
  }
  .frombox {
    padding: 50px 50px 30px 50px;
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }

    .demo-ruleForm {
      button {
        width: 200px;
        height: 40px;
      }
      .qux {
        margin-top: 50px;
        background-color: #fff;
        border: 1px solid #00bcff;
        color: #00bcff;
        margin-right: 20px;
      }
      .btn-next {
        background-color: #00bcff;
        border: none;
      }
    }
  }
}
</style>
