<template>
  <div class="onjob">
    <el-card class="box-card condition-box">
      <el-row style="padding: 0 10px">
        <el-col :span="14">
          <div class="header-left">
            <div class="font14">面试时间查询：</div>
            <el-date-picker
              type="date"
              placeholder="起"
              v-model="pageInfo.startDate"
              style="width: 182px"
            ></el-date-picker>
            <span style="margin: 0 15px"></span>
            <el-date-picker
              type="date"
              placeholder="止"
              @change="selectStatus"
              v-model="pageInfo.endDate"
              style="width: 182px"
            ></el-date-picker>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="header-right" style="text-align: right">
            <el-select
              v-model="pageInfo.companyJobId"
              placeholder="全部职位"
              @change="selectStatus"
              style="width: 130px; margin-right: 50px"
            >
              <el-option
                v-for="item2 in positionoptions"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="pageInfo.status"
              placeholder="面试状态"
              @change="selectStatus"
              style="width: 130px"
            >
              <el-option
                v-for="item2 in msptions"
                :key="item2.value"
                :label="item2.label"
                :value="item2.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- <div class="handshake-tishi">您有5条待面试 ，3条面试已拒绝</div> -->
    <!-- 无数据 -->
    <div class="nodata" v-if="userList != null && userList.length == 0">
      <img src="../../assets/images/person/p-nodata.png" />
      <div class="font16">当前无数据</div>
    </div>
    <el-card class="p-list" v-for="(itemes, index) in userList" :key="index">
      <div class="process" @click="todetail(itemes)">
        <div class="p-left">
          <div class="pl-one">
            <div class="p-picurl">
              <img
                :src="'https://freemen.work/user/file/view/' + itemes.avatar"
              />
              <span
                class="bofang font20"
                v-if="itemes.resUrl"
                @click.stop="videoshow(itemes)"
              >
                <!-- <i class="el-icon-video-play"></i> -->
                <img src="../../assets/images/common/bofangicon.png" />
              </span>
            </div>
            <div class="p-info">
              <div class="pn-name">
                <div class="namemain">
                  <span class="font22">{{ itemes.displayName }}</span>
                  <img
                    src="../../assets/images/person/boy.png"
                    v-if="itemes.sex == 1"
                  />
                  <img src="../../assets/images/person/girl.png" v-else />
                  <span class="ty font14" v-if="itemes.status == '0'"
                    >待回复</span
                  >
                  <span class="dai font14" v-if="itemes.status == '1'"
                    >待面试</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '2'"
                    >已拒绝</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '3'"
                    >未通过</span
                  >
                  <span class="jujue font14" v-if="itemes.status == '4'"
                    >已通过</span
                  >
                  <span class="huifu font14" v-if="itemes.status == '5'"
                    >已超时</span
                  >
                </div>
                <div class="score font14">
                  <span>{{ itemes.score }}</span>
                </div>
              </div>
              <div class="beizhu font14">
                <!-- {{itemes.expYear}}年 | {{itemes.educationType ? itemes.educationType : '不限'}} | {{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁 | {{itemes.jobLevelNames}} -->
                {{ itemes.expYear }}年 |
                {{
                  new Date().getYear() -
                  new Date(itemes.birthday).getYear() -
                  (new Date().getMonth() > new Date(itemes.birthday).getMonth()
                    ? 0
                    : 1)
                }}岁 | {{ itemes.jobLevelNames }}
              </div>
            </div>
          </div>
          <div class="pl-two font16">应聘岗位：{{ itemes.companyJobName }}</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="p-right font14">
          <div class="" @click.stop="todetail(itemes)">
            {{ itemes.interviewAddress }}
          </div>
          <div class="" @click.stop="todetail(itemes)">
            联系人：{{ itemes.interviewContact }}
          </div>
          <div class="" @click.stop="todetail(itemes)">
            联系电话：{{ itemes.interviewPhone }}
          </div>
          <div class="" @click.stop="todetail(itemes)">
            面试类型：{{ itemes.interviewType == 1 ? "线下面试" : "线上面试" }}
          </div>
          <div class="" @click.stop="todetail(itemes)">
            面试时间：{{ itemes.interviewDate }}
          </div>
          <!-- v-if="itemes.status=='4'" -->
          <!-- <el-button class="pass" type="primary" v-if="itemes.status==4" @click="sendoffer(itemes)">
						发送offer
					</el-button> -->
          <div v-if="itemes.status == 1">
            <el-button
              class="again"
              type="primary"
              @click.stop="sendMs(itemes, 1)"
              >面试通过</el-button
            >
            <el-button
              class="fail"
              type="primary"
              @click.stop="sendNoMs(itemes, 2)"
              >面试未通过</el-button
            >
          </div>
          <!-- <el-button class="fail" type="primary" v-if="itemes.status == 2"
            >面试未通过</el-button
          > -->
          <el-button
            class="again"
            type="primary"
            @click.stop="sendInterview(itemes)"
            v-if="itemes.status == '2' || itemes.status == '5'"
            >重新发起面试</el-button
          >
        </div>
      </div>
      <!-- v-if="moreBtn" -->
    </el-card>
    <div class="nextpage" @click="getMoreList" v-if="moreBtn">点击加载更多</div>
    <!-- 分页 -->
    <!-- <div class="paginate" style="position: relative;margin-top: 100px;">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pageInfo.pageNum"
				:page-size="pageInfo.pageSize"
				layout="total, prev, pager, next, jumper"
				:total="pageInfo.count"
			></el-pagination>
		</div> -->
    <!-- 重新发起面试 -->
    <el-dialog
      :visible.sync="interviewShow"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <el-form
          :model="interviewConfig"
          ref="interviewForm"
          label-position="top"
          label-width="130px"
          class="demo-ruleForm from-box"
        >
          <el-form-item
            label="面试时间"
            class="from-box-item"
            prop="interviewDate"
            :rules="[{ required: true, message: '请选择面试时间' }]"
          >
            <el-date-picker
              type="datetime"
              placeholder="请选择面试时间"
              v-model="interviewConfig.interviewDate"
              style="width: 360px; margin-left: 30px"
              format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="btnbox">
          <el-button class="qx" type="primary" @click="interviewShow = false"
            >取消</el-button
          >
          <el-button
            class="qr"
            type="primary"
            @click="interviewCommit('interviewForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 操作成功 -->
    <el-dialog
      :visible.sync="successDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <span class="closeicon" @click="successDialogVisible = false"
          ><i class="el-icon-error"></i
        ></span>
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">操作成功</div>
      </div>
    </el-dialog>
    <!-- 发布失败 -->
    <el-dialog
      :visible.sync="failDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <span class="closeicon" @click="failDialogVisible = false"
          ><i class="el-icon-error"></i
        ></span>
        <div class="ub-img">
          <img src="../../assets/images/person/failcha.png" />
        </div>
        <div class="ub-text font22">发布失败</div>
      </div>
    </el-dialog>
    <!-- 短信通知弹窗 -->
    <el-dialog
      :visible.sync="senddxDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="ub-text font22">面试发送成功，是否短信通知</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="senddxDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="sendMes">通知</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 短信不足弹窗 -->
    <el-dialog
      :visible.sync="dxbzDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/failcha.png" />
        </div>
        <div class="ub-text font22">当前短信卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button class="qx" type="primary" @click="interview = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buySms"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 发送offer -->
    <sendoffer @changestuts="changestuts"></sendoffer>
    <!-- 工作地点 -->
    <workingplace></workingplace>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
import sendoffer from "/src/components/person/sendoffer.vue"; //发送offer弹窗
import workingplace from "/src/components/person/workingplace.vue"; //工作地点弹窗
export default {
  components: {
    sendoffer,
    workingplace,
  },
  data() {
    return {
      formInline: {
        mssstuts: "全部",
        startTime: "",
        endTime: "",
        type: "",
        stuts: "",
        pageNum: 1,
        pageSize: 10,
      },
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        companyJobId: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      moreBtn: false,
      // 全部状态
      positionoptions: [{ value: "", label: "全部职位" }],
      msptions: [
        { value: "", label: "全部面试" },
        { value: 0, label: "待回复" },
        { value: 1, label: "待面试" },
        { value: 2, label: "已拒绝" },
        { value: 3, label: "未通过" },
        { value: 4, label: "已通过" },
        { value: 5, label: "已超时" },
      ],
      userList: null,
      interviewShow: false,
      interviewConfig: {
        interviewDate: "",
        interviewId: "",
        status: 0,
      },
      successDialogVisible: false, //操作成功弹窗
      failDialogVisible: false, //发布失败弹窗
      senddxDialogVisible: false, //短信发送弹窗
      dxbzDialogVisible: false, //短信不足弹窗
      messageList: [],
    };
  },
  activated() {
    this.getJobList();
    this.getList();
  },
  methods: {
    buySms() {
      this.interview = false;

      window.location.href = window.location.origin + "/#/propsmall";
    },
    getJobList() {
      //获取职位列表
      this.$api.joblist("get").then((res) => {
        this.positionoptions = [];
        res.data.map((item) => {
          if (item.status == 1) {
            let obj = {
              label: item.jobName,
              value: item.id,
            };
            this.positionoptions.push(obj);
          }
        });
      });
    },
    getList() {
      //获取数据列表
      let config = JSON.parse(JSON.stringify(this.pageInfo));
      if (this.pageInfo.startDate) {
        let startTime = new Date(config.startDate);
        let y = startTime.getFullYear();
        let m = startTime.getMonth() + 1;
        let d = startTime.getDate();
        config.startDate = y + "-" + m + "-" + d;
      }
      if (this.pageInfo.endDate) {
        let endTime = new Date(config.endDate);
        let y = endTime.getFullYear();
        let m = endTime.getMonth() + 1;
        let d = endTime.getDate();
        config.endDate = y + "-" + m + "-" + d;
      }
      this.$api.getInterviewList("get", config).then((res) => {
        if (config.pageNo == 1) {
          this.userList = res.data;
        } else {
          this.userList = this.userList.concat(res.data);
        }
        // this.userList = this.unique(this.userList);

        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getList();
    },
    selectStatus() {
      //选择职位
      this.pageInfo.pageNo = 1;
      this.userList = [];
      this.getList();
    },
    getMoreWork(data) {
      //展示所有工作经历
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      this.formInline.pageNum = this.pageInfo.pageNum;
      // this.getdata();
    },
    unique(arr) {
      let obj = {};
      return arr.filter((item) => {
        // eslint-disable-next-line no-prototype-builtins
        return obj.hasOwnProperty(
          typeof item.jobName + JSON.stringify(item.jobName)
        )
          ? false
          : (obj[typeof item.jobName + JSON.stringify(item.jobName)] = true);
      });
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    sendoffer(data) {
      Bus.$emit("offerstuts", {
        show: true,
        data: data,
      });
    },
    sendInterview(data) {
      this.interviewShow = true;
      this.interviewConfig.interviewId = data.id;
    },
    sendMs(items) {
      if (items.jobType != 1) {
        this.$api
          .reSendInterview("post", {
            interviewId: items.id,
            status: 4,
          })
          .then((res) => {
            if (res.code == 10200) {
              this.successDialogVisible = true;
              setTimeout(() => {
                this.successDialogVisible = false;
                window.location.reload();
              }, 1000);
            }
          });
      } else {
        Bus.$emit("offerstuts", {
          show: true,
          data: items,
        });
      }
    },
    sendNoMs(items) {
      this.$api
        .reSendInterview("post", {
          interviewId: items.id,
          status: 3,
        })
        .then((res) => {
          if (res.code == 10200) {
            this.successDialogVisible = true;
            setTimeout(() => {
              this.successDialogVisible = false;
              this.getList();
            }, 1000);
          }
        });
    },
    interviewCommit(formName) {
      //确认重新发送面试邀请
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = JSON.parse(JSON.stringify(this.interviewConfig));
          let startTime = new Date(config.interviewDate);
          let y = startTime.getFullYear();
          let m = startTime.getMonth() + 1;
          let d = startTime.getDate();
          let h = startTime.getHours();
          let f = startTime.getMinutes();
          let s = startTime.getSeconds();
          config.interviewDate =
            y + "-" + m + "-" + d + " " + h + ":" + f + ":" + s;
          this.$api.reSendInterview("post", config).then((res) => {
            let arr = [];
            arr.push(res.data.id);
            this.messageList = arr;
            this.interviewShow = false;
            this.senddxDialogVisible = true;
          });
        } else {
          return false;
        }
      });
    },
    sendMes() {
      //发送短信
      this.$api.sendMessage("post", this.messageList).then((res) => {
        if (res.code == "10901") {
          this.senddxDialogVisible = false;
          this.dxbzDialogVisible = true;
          return;
        }
        this.senddxDialogVisible = false;
        this.$message({
          message: "发送成功",
          type: "success",
        });
      });
    },
    changestuts(e) {
      this.senddxDialogVisible = e;
    },
    todetail(data) {
      if (data.isCompanyRead != 1) {
        this.$api.interviewRead("post", { id: data.id }).then(() => {});
      }
      this.$resumeDetail.show({
        show: true,
        type: "zaizhi",
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        positionoptions: this.positionoptions,
        callback: () => {
          let tempQuery = {
            active: 1,
            companyJobId: data.companyJobId,
            companyJobName: data.companyJobName,
            userJobId: data.userJobId,
            userId: data.userId,
          }
          this.$router.push({ path: "chatmain", query: tempQuery});
        },
      });
    },
    videoshow(data) {
      let config = {
        show: true,
        data: data,
      };
      this.$videoDetail.show(config);
    },
  },
};
</script>

<style lang="less" scoped>
.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #111111;
  padding: 37px 0 20px 0;
  img {
    width: 256px;
    height: 215px;
    margin-bottom: 20px;
  }
}
.nextpage {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  cursor: pointer;
  margin-top: 15px;
}
.condition-box {
  // background-color: #ffffff;
  // padding: 22px 0;
  // box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
  /deep/ .el-input__inner {
    border-radius: 0px;
    // border: none;
    // background-color: #f3f3f3;
  }
  .header-left {
    display: flex;
    align-items: center;
    color: #111111;
  }
  .header-right {
    /deep/ .el-input__inner {
      border-radius: 0px;
      // background-color: #00bcff;
      // border: none;
      // color: #FFFFFF;
    }
  }
}
.handshake-tishi {
  margin-top: 20px;
  background-color: #feecde;
  text-align: center;
  color: #ff5400;
  padding: 14px 0;
  box-shadow: 0px 1px 29px 17px rgb(0 0 0 / 3%);
}
.p-list:hover {
  transform: scale(1.02);
  background-color: rgba(0, 0, 0, 0.001);
}
.p-list {
  margin-top: 20px;
  position: relative;
  .process {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px 30px;
    .p-left {
      padding-right: 30px;
      display: flex;
      flex-direction: column;
      flex: 1;
      .pl-one {
        display: flex;
        align-items: center;
        .p-picurl {
          margin-right: 15px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .p-info {
          display: flex;
          // align-items: center;
          justify-content: flex-start;
          flex-direction: column;
          flex: 1;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 1.5;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              // span:last-child {
              // 	background-color: #e9f9ff;
              // 	color: #00bcff;
              // 	padding: 2px 10px;
              // }
              .ty {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
              .jujue {
                background-color: #f3f3f3;
                color: #999999;
                padding: 2px 10px;
              }
              .dai {
                color: #ff5400;
                background-color: #feecde;
                padding: 2px 10px;
              }
              .huifu {
                color: #ff2e2e;
                background-color: #ffeeee;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
          }
        }
      }
      .pl-two {
        color: #111111;
        margin-top: 20px;
      }
    }
    /deep/.el-divider--vertical {
      height: 4em;
      background-color: #f3f3f3;
    }
    .p-right {
      display: flex;
      // align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      color: #666666;
      flex: 1;
      padding-left: 30px;
      line-height: 1.8;
      position: relative;
      .pass {
        position: absolute;
        right: 0px;
        top: -30%;
        border-radius: 0px;
        height: 40px;
        width: 120px;
        margin-top: 50px;
        background: #00bcff;
        border: none;
      }
      .fail {
        position: absolute;
        right: 0px;
        top: 20%;
        border-radius: 0px;
        height: 40px;
        width: 120px;
        margin-top: 50px;
        background: #ffffff;
        border: 1px solid #dddddd;
        color: #999999;
      }
      .again {
        position: absolute;
        right: 0px;
        top: -20%;
        border-radius: 0px;
        height: 40px;
        width: 120px;
        margin-top: 50px;
        background: #00bcff;
        border: none;
      }
    }
  }
}
.updown_box {
  line-height: 3;
  padding: 60px 0;
  position: relative;
  .from-box-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .closeicon {
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}
</style>
